import $ from 'jquery'
import { Fancybox } from '@fancyapps/ui'
import { trackEventLightbox } from './analytics'
import { createWatchlistHistory } from './profileUtils'

window.Fancybox = Fancybox
if (document.documentElement.getAttribute('lang') == 'sl') {
  Fancybox.defaults.l10n = {
    PANUP: 'Premakni gor',
    PANDOWN: 'Premakni dol',
    PANLEFT: 'Premakni levo',
    PANRIGHT: 'Premakni desno',
    ZOOMIN: 'Povečaj',
    ZOOMOUT: 'Pomanjšaj',
    TOGGLEZOOM: 'Preklopi raven povečave',
    TOGGLE1TO1: 'Preklopi raven povečave',
    ITERATEZOOM: 'Preklopi raven povečave',
    ROTATECCW: 'Zavrti v nasprotni smeri urinega kazalca',
    ROTATECW: 'Zavrti v smeri urinega kazalca',
    FLIPX: 'Zrcali vodoravno',
    FLIPY: 'Zrcali navpično',
    FITX: 'Prilagodi vodoravno',
    FITY: 'Prilagodi navpično',
    RESET: 'Ponastavi',
    TOGGLEFS: 'Preklopi celoten zaslon',
    CLOSE: 'Zapri',
    NEXT: 'Naslednja',
    PREV: 'Prejšnja',
    MODAL: 'To vsebino lahko zaprete s tipko ESC',
    ERROR: 'Prišlo je do napake, poskusite znova kasneje',
    IMAGE_ERROR: 'Slika ni bila najdena',
    ELEMENT_NOT_FOUND: 'HTML element ni bil najden',
    AJAX_NOT_FOUND: 'Napaka pri nalaganju AJAX-a: Ni bilo najdeno',
    AJAX_FORBIDDEN: 'Napaka pri nalaganju AJAX-a: Prepovedano',
    IFRAME_ERROR: 'Napaka pri nalaganju strani',
    TOGGLE_ZOOM: 'Preklopi raven povečave',
    TOGGLE_THUMBS: 'Preklopi sličice',
    TOGGLE_SLIDESHOW: 'Preklopi diaprojekcijo',
    TOGGLE_FULLSCREEN: 'Preklopi na celozaslonski način',
    DOWNLOAD: 'Prenesi',
    CUSTOM_INFO: 'Informacije in pravice'
  }
} else {
  Fancybox.defaults.l10n.CUSTOM_INFO = 'Info and copyright'
}

let captionsVisible
function toggleCaptions(toolbar, toggle) {
  if (typeof toggle !== 'undefined') {
    captionsVisible = toggle
  } else {
    captionsVisible = !captionsVisible
  }

  const fancyboxContainer = toolbar.instance.container
  if (captionsVisible) {
    fancyboxContainer.classList.remove('fancybox__no-captions')
  } else {
    fancyboxContainer.classList.add('fancybox__no-captions')
  }
}

const infoButton = `
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
</svg>
`

const CustomToolbar = {
  // Specify the buttons to display on the toolbar
  enabled: true,
  display: {
    left: ['infobar'],
    middle: [],
    right: ['toggleCaptions', 'slideshow', 'thumbs', 'close']
  },
  items: {
    toggleCaptions: {
      type: 'button',
      label: 'Toggle Captions',
      tpl: `<button title="${Fancybox.defaults.l10n.CUSTOM_INFO}" class="f-button">${infoButton}</button>`,
      click: function(toolbar) {
        toggleCaptions(toolbar)
      }
    }
  }
}

function getFancyboxDefaults() {
  return {
    hideScrollbar: false,
    Html: {
      autoSize: false
    },
    Images: {
      protected: true
    },
    Thumbs: {
      type: 'classic',
      showOnStart: !window.isMobile
    },
    Toolbar: CustomToolbar,
    on: {
      ready: function(fancybox) {
        toggleCaptions(fancybox.plugins.Toolbar, captionsVisible)
      },
      'Carousel.ready Carousel.change': fancybox => {
        const triggerEl = $(fancybox.getSlide().triggerEl)

        trackEventLightbox(triggerEl)
        const video = triggerEl.attr('data-video')
        if (video) {
          createWatchlistHistory(triggerEl)
        }
      }
      //'*': (fancybox, eventName) => {
      ////console.log(`Fancybox eventName: ${eventName}`)
      //}
    }
  }
}

export function galleryFancybox($el, opts=null) {
  $el.forEach(el => {
    Fancybox.bind(el, {
      ...getFancyboxDefaults(),
      ...opts
    })
  })
}

export default function initFancybox() {
  captionsVisible = window.isMobile ? false : true

  Fancybox.bind('[data-gallery]', {
    ...getFancyboxDefaults()
  })

  Fancybox.bind('[data-fancybox][data-gallery-pdf]', {
    ...getFancyboxDefaults()
  })
}


$.fn.setDataFancyboxIndex = function() {
  return this.each(function(index) {
    this.dataset.fancyboxIndex = index
  })
}

window.galleryFancybox = galleryFancybox
