import $ from 'jquery'

export function createWatchlistHistory(video) {
  $.post(`/${window.language}/api/v2/watch-history/`, { video })
}

const updateEl = ($el, exists) => {
  $el.attr('disabled', false)
  $el.attr('data-exists', exists)
  $el.toggleClass('movie-list--exists', exists)
  // update tooltip
  const label = exists
    ? $el.attr('data-title-remove')
    : $el.attr('data-title-add')
  $el
    .tooltip('dispose')
    .attr('title', label)
    .tooltip()
}

function setupElement(el, baseUrl, targetName) {
  const $el = $(el)
  let exists = false
  $.getJSON(`${baseUrl}exists/`, {
    [targetName]: $el.attr('data-id')
  })
    .then(result => {
      exists = result
    })
    .always(() => {
      updateEl($el, exists)
      $el.click(() => {
        const currentExists = $el.attr('data-exists') == 'true'
        $(el).attr('disabled', true)
        $.post(baseUrl + (currentExists ? 'remove/' : ''), {
          [targetName]: $el.attr('data-id')
        }).always(() => {
          updateEl($el, !currentExists)
        })
      })
    })
}

$.fn.activateWishlist = function() {
  return this.each(function() {
    setupElement(this, `/${window.language}/api/v2/wishlist/`, 'avmedia')
  })
}


$.fn.activatePlaylist = function() {
  return this.each(function() {
    setupElement(this,  `/${window.language}/api/v2/playlist/`, 'video')
  })
}
