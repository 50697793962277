import $ from 'jquery'
import initFancybox from './fancyboxUtils5'

const loadingClass = 'results-list__loading--loading'
let ajaxId
let translations

function submitForm(el) {
  const currentId = new Date()
  ajaxId = currentId
  // TODO: cancel previous xhr requests
  const $form = el ? $(el).closest('form') : $('.search-sidebar form')
  //serialize form
  const query = $form.serializeArray().filter(function (el) {
    if (el.name.startsWith('__')) {
      return false
    }
    if (el.name.startsWith('page') && el.value == '1') {
      return false
    }
    return el.value !== ''
  })
  const url = $form.attr('action') + '?' + $.param(query)
  const $resultsListLoading = $('.results-list__loading')
  //set loading
  $resultsListLoading.addClass(loadingClass)
  //request json
  const jqxhr = $.getJSON(url)
  jqxhr.done(data => {
    if (currentId != ajaxId) {
      console.log('obsolete')
      return
    }
    window.history.pushState(data, null, url)
    //update results
    updateResults(data)
    //remove loading
    $resultsListLoading.removeClass(loadingClass)
  })
  //scroll to top of results
}

function prepareSearchResults() {
  $('.r__content a[data-fancybox-index=-1]').setDataFancyboxIndex()
}


function updateResults(data) {
  const $resultsListContainer = $('.results-list-container')
  updateSearchCounters(data.counters)
  updateSearchGroupsCounters(data.group_counters)
  $resultsListContainer.html(data.results)
  prepareSearchResults()
  initFancybox()
}

function updateCountLabel(labelEl, count) {
  let countEl = $('i', labelEl)
  const countText = `(${count})`
  if (countEl.length === 0) {
    labelEl.append(`<i>${countText}</i>`)
  } else {
    if (countEl.text() == countText) {
      return
    }
    countEl.text(countText)
  }
}

function updateCount(labelEl, count) {
  const inputEl = $(`#${labelEl.attr('for')}`)
  const disableField = count === 0 && !inputEl.is(':checked')
  updateCountLabel(labelEl, count)
  labelEl.toggleClass('label--disabled', disableField)
  inputEl.attr('disabled', disableField)
}

function updateSearchCounters(counters) {
  for (const key of Object.keys(counters)) {
    const value = counters[key]
    if (typeof value === 'number') {
      const labelEl = $(`[for=${key}]`)
      updateCount(labelEl, value)
    } else if (value.sliderRange) {
      const slider_el = $(`[data-name=${key}] > .slider__el`)
      slider_el.trigger('updateSliderRange', [value.sliderRange])
      updateFilterFieldReset(slider_el.closest('.form-group'))
      updateCategoryReset(slider_el.closest('.advanced-search__filter-body'))
    } else {
      Object.keys(value).forEach(optionValue => {
        const count = value[optionValue]
        const inputEl = $(`[name=${key}][value="${optionValue}"]`).get(0)
        if (inputEl) {
          const labelEl = $(`.custom-control-label[for=${inputEl.id}]`)
          updateCount(labelEl, count)
        }
      })
    }
  }
  $('.nested-checkboxes').nestedCheckboxes('refresh')
}

function updateSearchGroupsCounters(counters) {
  for (const key of Object.keys(counters)) {
    const labelEl = $(`#group-counter${key}`)
    updateCountLabel(labelEl, counters[key])
  }
}

function updateFormFields(params) {
  const filter = params.get('filter') || 'avmedia'
  $(`#search__filter--${filter}`).collapse('show')
  let inputEl
  for (let key of params.keys()) {
    inputEl = $(`[name=${key}]`)
    if (!inputEl.length) {
      continue
    }
    if (inputEl[0].type == 'checkbox') {
      const allValues = params.getAll(key)
      inputEl.each(function() {
        $(this).prop('checked', allValues.includes(this.value))
      })
      inputEl.val()
    } else {
      inputEl.val(params.get(key))
    }
  }
}

function removeFilter(el) {
  const $el = $(el)
  const name = $el.attr('data-update-input')
  const value = $el.attr('data-value')
  const widget = $el.attr('data-widget')
  let $inputEl
  if (widget === 'range') {
    $inputEl = $(`[data-name=${name}]`)
    const slider = $('.slider__el', $inputEl).get(0)
    slider.noUiSlider.set([
      slider.noUiSlider.options.range.min,
      slider.noUiSlider.options.range.max
    ])
  } else if (widget === 'multiple_checkbox') {
    $inputEl = $(`[name=${name}][value=${value}]`)
    $inputEl.prop('checked', false)
  } else if (widget === 'checkbox') {
    $inputEl = $(`[name=${name}]`)
    $inputEl.prop('checked', false)
  }
  $el.hide()
  updateFilterFieldReset($inputEl.closest('.form-group'))
  updateCategoryReset($inputEl.closest('.advanced-search__filter-body'))
  submitForm($inputEl)
}

function collapseFields(searchSidebar) {
  $('.form-group:not(.form-group--open) > label', searchSidebar).each(
    function() {
      const filterHeader = $(this)
      const filterBody = filterHeader
        .nextAll('div')
        .wrapAll('<div class="filter-field__body" />')
        .parent()
      const toggleEl = $('<span class="filter-field__toggle"></span>')
      filterHeader.addClass('filter-field__header collapsed')
      const resetEl = $('.advanced-search__reset', filterHeader)
      if (resetEl.length === 0 || resetEl.css('display') === 'none') {
        filterBody.collapse({ toggle: false })
        filterBody.addClass('collapse')
      }
      $(this).append(toggleEl)
      filterHeader.click(() => {
        filterBody.collapse('toggle')
        filterHeader.toggleClass('collapsed')
      })
    }
  )
}

function isDirty(groupEl) {
  let isDirty = false
  $(':input', groupEl).each(function() {
    if ($(this).is('[type=radio],[type=checkbox]')) {
      if ($(this).is(':checked')) {
        isDirty = true
        return false
      }
    } else if ($(this).val() !== '' && !$(this).hasClass('searchable-filter__search')) {
      isDirty = true
      return false
    }
  })
  return isDirty
}

function resetGroup(groupEl) {
  $('input[type=radio], input[type=checkbox]', groupEl).prop('checked', false)
  $('input[type=text]', groupEl).val('')
  $('.slider__el', groupEl).each(function() {
    const slider = $(this).get(0).noUiSlider
    slider.set([slider.options.range.min, slider.options.range.max])
  })
}

function updateGroupReset(labelEl, groupEl, resetLabel) {
  let resetEl = $('.advanced-search__reset', labelEl)
  const dirty = isDirty(groupEl)

  if (dirty) {
    if (resetEl.length === 0) {
      resetEl = $(
        `<a href="" class="advanced-search__reset">${resetLabel}</a>`
      )
      labelEl.append(resetEl)
      resetEl.on('click', function(e) {
        e.stopPropagation()
        e.preventDefault()
        resetGroup(groupEl)
        resetEl.hide()
        // hide children reset elements
        $('.advanced-search__reset', groupEl).hide()
        // update parent reset elements
        updateCategoryReset(labelEl.closest('.advanced-search__filter-body'))
        submitForm()
      })
    }
    resetEl.show()
  } else {
    resetEl.hide()
  }
}

//not used
export function filterType(filterEl) {
  if ($(filterEl).hasClass('filter-field--multiple')) {
    return 'checkboxes'
  }
  if ($(filterEl).hasClass('filter-field--radio')) {
    return 'radio'
  }
  if ($(filterEl).hasClass('filter-field--slider')) {
    return 'slider'
  }
}

function updateFilterFieldReset(filterEl) {
  const labelEl = $('> label.col-form-label', filterEl)
  updateGroupReset(labelEl, filterEl, translations.reset)
}

function updateCategoryReset(categoryEl) {
  const labelEl = categoryEl
    .parents('.section-body')
    .prev('.section-head')
    .children('.search-sidebar__section-title')
  if (labelEl.length === 0) {
    return
  }
  updateGroupReset($(labelEl), categoryEl, translations.reset_category)
}

export default function initSearchFilter() {
  const searchSidebar = $('#search-sidebar')
  if (!searchSidebar.length) {
    return
  }
  $('#id_name').clearableInput()

  const counters = JSON.parse(
    document.getElementById('search-counters').textContent
  )
  const groupsCounters = JSON.parse(
    document.getElementById('search-group-counters').textContent
  )
  translations = JSON.parse(
    document.getElementById('search-translations').textContent
  )
  const initialState = {
    counters,
    groupsCounters,
    results: $('.results-list-container').html()
  }
  const sectionCollapseElements = $('.section-body', searchSidebar)
  window.addEventListener('popstate', function(event) {
    updateResults(event.state || initialState)
    const params = new URLSearchParams(window.location.search)
    updateFormFields(params)
  })
  // adding counter to group should come first to avoid Reset beeing added in between
  // https://trello.com/c/G6dKnE8P/1114-advanced-search-testi-napake#comment-5e5a5f13a62bfb3dbb99073d
  updateSearchGroupsCounters(groupsCounters)
  updateSearchCounters(counters)
  const filterInpuEl = $('#advanced-search__filter')
  $(document).on('change', '#order-by', function() {
    const targetEl = $('#id_order_by')
    targetEl.val($(this).val())
    submitForm(targetEl)
  })
  $(':input', searchSidebar).change(e => {
    const elName = $(e.target).attr('name')
    if (!elName) {
      return
    }
    //resetPagination
    if (elName === 'name') {
      $('[name^=page]').val('1')
    } else {
      $(`[name=page${filterInpuEl.val()}]`).val('1')
    }
    submitForm(e.target)
  })

  // handle reset
  const filtersWithReset = $(
    '.filter-field--multiple, .filter-field--slider, .filter-field--radio',
    searchSidebar
  )
  $(':input', filtersWithReset).change(function() {
    updateFilterFieldReset($(this).closest('.form-group'))
  })
  filtersWithReset.each((index, e) => updateFilterFieldReset(e))
  collapseFields(searchSidebar)

  // initially set reset on categories
  const categories = $('.advanced-search__filter-body', searchSidebar)
  categories.each((index, e) => updateCategoryReset($(e)))
  $(':input', searchSidebar).change(function() {
    updateCategoryReset($(this).closest('.advanced-search__filter-body'))
  })

  // when section is opened, submit form
  sectionCollapseElements.on('show.bs.collapse', function(e) {
    // skip children sections
    if (!$(this).is(e.target)) {
      return
    }
    filterInpuEl.val(
      $(this)
        .prev('[data-set-filter]')
        .attr('data-set-filter')
    )
    submitForm()
  })

  // when section is closed, open first section
  const defaultSection = $('.section-head--collapsable').eq(0)
  $('.section-head--collapsable', searchSidebar).on('click', function(e) {
    // if default section and clicked collapse, do nothing
    if ($(this).is(defaultSection) && !defaultSection.hasClass('collapsed')) {
      e.stopPropagation()
      return
    }
    if (!$(this).hasClass('collapsed')) {
      sectionCollapseElements.eq(0).collapse('show')
    }
  })

  $('.results-list-container').on('click', '[data-page-number]', function(e) {
    e.preventDefault()
    const pageLinkEl = $(e.target).closest('a')
    const el = $(`[name=${pageLinkEl.attr('data-page')}`)
    el.val(pageLinkEl.attr('data-page-number'))
    submitForm(el)
  })
  $('.results-list-container').on('click', '[data-update-input]', function(e) {
    e.preventDefault()
    removeFilter(this)
  })
}

window.prepareSearchResults = prepareSearchResults
